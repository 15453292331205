<template>
  <v-card>
    <v-card-title class="text-h6 mb-3">
      <span v-if="updateItem.id">
        {{ $t("labels.tracking") }}:
        {{ updateItem.tracking_id }}
      </span>
      <span v-else>
        {{ $t("labels.present") }}
      </span>
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="close">
        {{ !isDetailShow ? $t("labels.cancel") : $t("labels.close") }}
      </v-btn>
      <v-btn
        v-if="!isDetailShow"
        color="green darken-1"
        text
        @click="confirm"
        :disabled="isDisabledBtn"
      >
        {{ $t("labels.confirm") }}
      </v-btn>
    </v-card-title>

    <v-card-text v-if="!isDetailShow" class="mb-3">
      <v-row>
        <v-col cols="3">
          <select-multiple
            v-model="item.id_warehouses"
            :options="warehouseOptions"
            :label="$t('labels.warehouse_1')"
            custom-class="c-input-small"
          ></select-multiple>
        </v-col>
        <v-col cols="3">
          <select-multiple
            v-model="item.sources"
            :options="sourceOptions"
            :label="$t('labels.order_source')"
            custom-class="c-input-small"
          ></select-multiple>
        </v-col>
        <v-col cols="3">
          <select-multiple
            v-model="item.id_shops"
            :options="shopOptions"
            :label="$t('labels.shop')"
            custom-class="c-input-small"
          ></select-multiple>
        </v-col>
        <v-col>
          <v-autocomplete
            v-model="item.type"
            :items="typeOptions"
            :label="$t('labels.promotion_type')"
            class="c-input-small"
            outlined
            dense
            hide-details
          ></v-autocomplete>
        </v-col>

        <v-col cols="3">
          <v-text-field
            v-model="item.name"
            :label="$t('labels.program_name')"
            class="c-input-small"
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>

        <v-col>
          <v-autocomplete
            v-model="item.is_allow_combo"
            :items="yesNoOptions"
            :label="$t('labels.is_allow_combo')"
            class="c-input-small"
            outlined
            dense
            hide-details
          ></v-autocomplete>
        </v-col>

        <v-col cols="3" :key="`start_time_${item.id}`">
          <date-picker-model
            v-model="item.start_time"
            :label="$t('labels.start')"
            :min-date="minDate"
            :single-line="false"
          ></date-picker-model>
        </v-col>

        <v-col cols="3" :key="`end_time_${item.id}`">
          <date-picker-model
            v-model="item.end_time"
            :label="$t('labels.end')"
            :min-date="minDate"
            :single-line="false"
          ></date-picker-model>
        </v-col>

        <!-- <v-col cols="3">
          <v-text-field
            v-model="item.note"
            :label="$t('labels.note')"
            class="c-input-small"
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col> -->
      </v-row>
    </v-card-text>
    <v-card-text v-else>
      <table class="w-100">
        <tbody style="vertical-align: top">
          <tr>
            <td style="width: 6%" class="black--text font-weight-medium">
              {{ $t("labels.warehouse_1") }}:
            </td>
            <td style="width: 19%">{{ updateItem.warehouse_codes }}</td>

            <td style="width: 6%" class="black--text font-weight-medium">
              {{ $t("labels.source") }}:
            </td>
            <td style="width: 19%">{{ updateItem.source_names }}</td>

            <td style="width: 6%" class="black--text font-weight-medium">
              {{ $t("labels.shop") }}:
            </td>
            <td style="width: 19%">{{ updateItem.shop_names }}</td>

            <td style="width: 6%" class="black--text font-weight-medium">
              {{ $t("labels.promotion_type") }}:
            </td>
            <td style="width: 19%">
              {{
                updateItem.type == 1
                  ? $t("labels.by_product")
                  : $t("labels.by_value")
              }}
            </td>
          </tr>

          <tr>
            <td class="black--text font-weight-medium">
              {{ $t("labels.name") }}:
            </td>
            <td>{{ updateItem.name }}</td>

            <td class="black--text font-weight-medium">
              {{ $t("labels.is_allow_combo") }}:
            </td>
            <td>
              {{
                updateItem.is_allow_combo ? $t("labels.yes") : $t("labels.no")
              }}
            </td>

            <td class="black--text font-weight-medium">
              {{ $t("labels.start") }}:
            </td>
            <td>{{ formatDateTime(updateItem.start_time, "DD/MM/YYYY") }}</td>

            <td class="black--text font-weight-medium">
              {{ $t("labels.end") }}:
            </td>
            <td>{{ formatDateTime(updateItem.end_time, "DD/MM/YYYY") }}</td>

            <!-- <td class="black--text font-weight-medium">
              {{ $t("labels.note") }}:
            </td>
            <td>{{ updateItem.note }}</td> -->
          </tr>
        </tbody>
      </table>
    </v-card-text>

    <v-card-text v-show="tab == 'rule'">
      <v-simple-table
        height="calc(100vh - 400px)"
        class="table-padding-2-no-top table-h-36 table-border-all"
      >
        <template v-slot:default>
          <!-- <thead class="v-data-table-header">
            <tr>
              <th
                :colspan="!isDetailShow ? 8 : 7"
                class="fs-16px primary--text"
              >
                {{ $t("labels.rule") }}
              </th>
            </tr>
          </thead> -->
          <tbody>
            <!-- <tr v-if="!isDetailShow">
              <td colspan="10">
                <SelectSuggestGoodsNoWarehouse
                  @onSelect="selectRule"
                  :select-items="rules"
                />
              </td>
            </tr> -->
            <tr class="font-weight-medium text-center">
              <td style="width: 125px">
                {{
                  item.type == 1
                    ? $t("labels.point_from")
                    : $t("labels.amount_from")
                }}
              </td>
              <td>{{ $t("labels.barcode") }}</td>
              <td style="width: 125px">{{ $t("labels.present_quantity") }}</td>
              <td>{{ $t("labels.sku") }}</td>
              <td>{{ $t("labels.name") }}</td>
              <td>{{ $t("labels.goods_description") }}</td>
              <td>{{ $t("labels.size") }}</td>
              <!-- <td style="width: 100px">{{ $t("labels.image") }}</td>
              <td>{{ $t("labels.category") }}</td> -->
              <td class="text-center" style="width: 165px" v-if="!isDetailShow">
                <v-btn color="success" x-small @click="addRule()" class="mr-1">
                  {{ $t("labels.add_rule") }}
                </v-btn>
                <v-btn
                  v-if="rules.length > 0"
                  color="purple"
                  class="white--text"
                  x-small
                  @click="clearRule()"
                >
                  {{ $t("labels.clear") }}
                </v-btn>
              </td>
            </tr>
          </tbody>
          <tbody
            v-for="(cItem, cIndex) in rules"
            :key="`r_${cIndex}_${cItem.id}`"
          >
            <tr
              class="text-center"
              v-for="(rItem, rIndex) in cItem.items"
              :key="`ri_${cIndex}_${cItem.id}_${rIndex}`"
            >
              <td :rowspan="cItem.items.length" v-if="rIndex == 0">
                <template v-if="!isDetailShow">
                  <v-text-field
                    v-if="item.type == 1"
                    v-model.number="cItem.quantity"
                    class="c-input-small"
                    outlined
                    dense
                    hide-details
                    single-line
                    type="number"
                  ></v-text-field>
                  <input-number
                    v-else
                    v-model.number="cItem.quantity"
                    type="number"
                    outlined
                    dense
                    hide-details
                    class="c-input-small"
                  />
                </template>
                <span v-else>
                  {{
                    item.type == 1
                      ? cItem.quantity
                      : formatNumber(cItem.quantity)
                  }}
                </span>
              </td>
              <td>
                <SelectSuggestGoodsLimit
                  v-if="!isDetailShow"
                  :index="cIndex"
                  :obj="{ cItem, rItem, rIndex }"
                  :default-value="rItem.customer_goods_barcode"
                  @onSelect="selectRuleItem"
                />
                <span v-else>
                  {{ rItem.customer_goods_barcode }}
                </span>
              </td>
              <td>
                <input-number
                  v-if="!isDetailShow"
                  v-model.number="rItem.quantity"
                  type="number"
                  outlined
                  dense
                  hide-details
                  class="c-input-small"
                />
                <span v-else>{{ rItem.quantity }}</span>
              </td>
              <td>{{ rItem.sku }}</td>
              <td>{{ rItem.name }}</td>
              <td>{{ rItem.description }}</td>
              <td>{{ rItem.size }}</td>
              <!-- <td>
                <ImageViewer
                  v-if="rItem.url_images"
                  :url="rItem.url_images"
                  width="40px"
                  height="40px"
                />
              </td>
              <td>{{ rItem.category_name }}</td>-->
              <td
                v-if="!isDetailShow && rIndex == 0"
                :rowspan="cItem.items.length"
              >
                <!-- <v-btn
                  x-small
                  color="error"
                  @click="removeRule(cItem)"
                  class="mb-1"
                >
                  {{ $t("labels.delete") }}
                </v-btn> -->
                <v-btn
                  color="primary"
                  x-small
                  class="mr-1"
                  @click="addRuleChild(cItem, cIndex)"
                >
                  {{ $t("labels.add_item") }}
                </v-btn>
                <v-btn color="error" x-small @click="removeRule(cIndex)">
                  {{ $t("labels.delete") }}
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>

    <v-card-text v-if="item.type == 1" v-show="tab == 'product'">
      <v-simple-table
        height="calc(100vh - 400px)"
        class="table-padding-2-no-top table-h-36"
        fixed-header
      >
        <template v-slot:default>
          <!-- <thead class="v-data-table-header">
            <tr>
              <th
                :colspan="!isDetailShow ? 9 : 8"
                class="fs-16px primary--text"
              >
                {{ $t("labels.product") }}
              </th>
            </tr>
          </thead> -->
          <tbody>
            <tr v-if="!isDetailShow">
              <td colspan="9">
                <SelectSuggestGoodsNoWarehouse
                  @onSelect="selectItem"
                  :select-items="items"
                />
              </td>
            </tr>
            <tr class="font-weight-medium text-center">
              <td style="width: 100px">{{ $t("labels.image") }}</td>
              <td>{{ $t("labels.sku") }}</td>
              <td>{{ $t("labels.barcode") }}</td>
              <td>{{ $t("labels.category") }}</td>
              <td>{{ $t("labels.name") }}</td>
              <td>{{ $t("labels.goods_description") }}</td>
              <td>{{ $t("labels.size") }}</td>
              <td style="width: 100px">{{ $t("labels.point") }}</td>
              <td class="text-center" style="width: 75px" v-if="!isDetailShow">
                <v-btn
                  v-if="items.length > 0"
                  color="purple"
                  class="white--text"
                  x-small
                  @click="clearItem()"
                >
                  {{ $t("labels.clear") }}
                </v-btn>
              </td>
            </tr>
            <tr
              class="text-center"
              v-for="(cItem, cIndex) in items"
              :key="`c_${cIndex}_${cItem.id}`"
            >
              <td>
                <ImageViewer
                  v-if="cItem.url_images"
                  :url="cItem.url_images"
                  width="40px"
                  height="40px"
                />
              </td>
              <td>{{ cItem.sku }}</td>
              <td>{{ cItem.customer_goods_barcode }}</td>
              <td>{{ cItem.category_name }}</td>
              <td>{{ cItem.name }}</td>
              <td>{{ cItem.description }}</td>
              <td>{{ cItem.size }}</td>
              <td>
                <v-text-field
                  v-if="!isDetailShow"
                  v-model.number="cItem.point"
                  class="c-input-small"
                  outlined
                  dense
                  hide-details
                  single-line
                  type="number"
                ></v-text-field>
                <span v-else>{{ cItem.point }}</span>
              </td>
              <td v-if="!isDetailShow">
                <v-btn
                  x-small
                  color="error"
                  @click="removeItem(cItem)"
                  class="mb-1"
                >
                  {{ $t("labels.delete") }}
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    <v-card-text>
      <v-row>
        <v-col cols="3">
          <v-btn
            block
            color="primary"
            :outlined="!isActiveTab('rule')"
            @click="changeTab('rule')"
          >
            {{ $t("labels.rule") }}
          </v-btn>
        </v-col>
        <v-col cols="3" v-if="item.type == 1">
          <v-btn
            block
            color="primary"
            :outlined="!isActiveTab('product')"
            @click="changeTab('product')"
          >
            {{ $t("labels.product") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment/moment";
import { httpClient } from "@/libs/http";
import {
  PRESENT_TYPES,
  ORDER_SOURCES,
  YES_NO_LANG_OPTIONS,
} from "@/libs/const";

export default {
  name: "PresentCreate",
  components: {
    SelectSuggestGoodsNoWarehouse: () =>
      import("@/components/common/SelectSuggestGoodsNoWarehouse"),
    SelectSuggestGoodsLimit: () =>
      import("@/components/common/SelectSuggestGoodsLimit"),
    ImageViewer: () => import("@/components/common/ImageViewer"),
  },
  props: {
    updateItem: {
      type: Object,
      default: () => {},
    },
    isDetailShow: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    pointOptions: {
      length: 8,
      precision: 2,
    },
    tab: "rule",
    warehouseOptions: [],
    sourceOptions: [...ORDER_SOURCES],
    typeOptions: [...PRESENT_TYPES],
    yesNoOptions: [...YES_NO_LANG_OPTIONS],
    shops: [],
    startDate: null,
    startTime: "00:00",
    endDate: null,
    endTime: "23:59",
    item: {
      id_warehouses: [],
      sources: [],
      id_shops: [],
      name: null,
      type: 2,
      is_allow_combo: 0,
      start_time: null,
      end_time: null,
      note: null,
      id: null,
    },
    items: [],
    rules: [],
    isLoading: false,
  }),
  computed: {
    minDate() {
      return moment().add(1, "day").format("YYYY-MM-DD");
    },
    isDisabledBtn() {
      if (!this.item.id_warehouses || this.item.id_warehouses.length === 0) {
        return true;
      }

      if (!this.item.sources || this.item.sources.length === 0) {
        return true;
      }

      const eMarketSources = [...this.item.sources].filter(
        (source) => source > 0
      );
      if (
        eMarketSources &&
        eMarketSources.length > 0 &&
        (!this.item.id_shops || this.item.id_shops.length == 0)
      ) {
        return true;
      }

      if (!this.item.name || !this.item.type) {
        return true;
      }

      if (!this.item.start_time || !this.item.end_time) {
        return true;
      }

      return false;
    },
    startTimeConverted() {
      return !this.startDate || !this.startTime
        ? null
        : `${this.startDate} ${this.startTime}`;
    },
    endTimeConverted() {
      return !this.endDate || !this.endTime
        ? null
        : `${this.endDate} ${this.endTime}`;
    },
    shopOptions() {
      if (!this.item.sources || this.item.sources.length == 0) {
        return [];
      }

      return [...this.shops]
        .filter((shop) => this.item.sources.includes(shop.id_e_marketplace))
        .map((shop) => ({
          text: shop.display_name,
          value: shop.id,
        }));
    },
  },
  mounted() {
    const { warehouses } = window.me;
    this.warehouseOptions = [...warehouses].map((w) => ({
      value: w.id,
      text: w.code_name || "",
    }));
    this.getItems();
    this.getShop();
    if (this.updateItem && this.updateItem.id) {
      const sources = (this.updateItem.sources || "")
        .split(";")
        .map((source) => +source);

      const id_warehouses = (this.updateItem.id_warehouses || "")
        .split(";")
        .map((id_warehouse) => +id_warehouse);

      const id_shops = (this.updateItem.id_shops || "")
        .split(";")
        .map((id_shop) => +id_shop);

      this.item = {
        ...this.item,
        id_warehouses,
        sources,
        id_shops,
        id: this.updateItem.id,
        name: this.updateItem.name,
        note: this.updateItem.note,
        type: this.updateItem.type,
        is_allow_combo: this.updateItem.is_allow_combo,
        start_time: moment(this.updateItem.start_time).format("YYYY-MM-DD"),
        end_time: moment(this.updateItem.end_time).format("YYYY-MM-DD"),
      };
    }
  },
  methods: {
    changeTab(tab) {
      this.tab = tab;
    },

    isActiveTab(tab) {
      return this.tab == tab;
    },

    selectItem(item) {
      this.items.push({
        ...item,
        point: 1,
      });
    },

    removeItem(item) {
      const items = [...this.items].filter((it) => it.id !== item.id);
      this.items = [...items];
    },

    clearItem() {
      const items = [];
      this.items = [...items];
    },

    addRule() {
      this.rules.push({
        quantity: "",
        items: [
          {
            customer_goods_barcode: "",
            quantity: "",
          },
        ],
      });
    },
    delRule(index) {
      this.rules = [...this.rules].filter((item, i) => i !== index);
    },

    selectRuleItem({ item, index, obj }) {
      if (!item.customer_goods_barcode) {
        return false;
      }
      console.log(item, index, obj);
      const { rIndex, rItem } = obj;
      const rules = [...this.rules];
      const items = [...rules[index].items];
      items[rIndex] = { ...rItem, ...item };
      rules[index].items = [...items];
      this.rules = [...rules];
    },

    addRuleChild(item, index) {
      const rules = [...this.rules];
      rules[index].items.push({
        customer_goods_barcode: "",
        quantity: "",
      });
      // rules[index] = { ...item };
      this.rules = [...rules];
    },

    selectRule(item) {
      this.rules.push({
        ...item,
        point: "",
        quantity: "",
      });
    },

    removeRule(index) {
      const rules = [...this.rules].filter((it, idx) => idx !== index);
      this.rules = [...rules];
    },

    clearRule() {
      const rules = [];
      this.rules = [...rules];
    },

    close() {
      this.$emit("close");
    },

    async confirm() {
      if (this.isDisabledBtn) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/present-save", {
          ...this.item,
          items: this.items,
          rules: this.rules,
        });
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.update_success"));
        this.$emit("refreshData");
        this.close();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },

    onStartDateChange(val) {
      this.startDate = val.value;
    },

    onEndDateChange(val) {
      this.endDate = val.value;
    },

    async getShop() {
      try {
        const { data } = await httpClient.post("/get-all-e-market-shop", {
          active: 1,
        });
        this.shops = [...data];
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },

    async getItems() {
      if (!this.updateItem.id) {
        return false;
      }
      try {
        const { data } = await httpClient.post("/present-get-items-rules", {
          id: this.updateItem.id,
        });
        this.items = [...data.items];
        this.rules = [...data.rules];
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
  },
};
</script>

<style scoped></style>
